import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  enable() {
    this.inputTargets.forEach(inputTarget => inputTarget.removeAttribute('disabled'))
  }

  disable() {
    this.inputTargets.forEach(inputTarget => inputTarget.setAttribute('disabled', true))
  }
}
