import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tableRow']

  enableRow(event) {
    event.currentTarget.classList.add('hidden')
    this.tableRowTarget.classList.remove('hidden')
    event.preventDefault()
  }
}
