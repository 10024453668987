import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['reuse']

  disableReuse() {
    this.reuseTarget.disabled = true
    this.reuseTarget.checked = false
  }

  enableReuse() {
    this.reuseTarget.disabled = false
  }
}
