import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['pastDueInput', 'pastDueWrapper']

  change(e) {
    e.preventDefault()

    const select = e.target
    if (select.value === 'Past Due' || select.value === '1st Payment Default') {
      this.pastDueWrapperTarget.classList.remove('hidden')
    } else {
      this.pastDueWrapperTarget.classList.add('hidden')
      this.pastDueInputTarget.value = null
    }
  }
}
