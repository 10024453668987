import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['customerPaidInput', 'customerPaidWrapper']

  change(e) {
    e.preventDefault()

    const select = e.target
    if (select.value === 'customer_paid') {
      this.customerPaidWrapperTarget.classList.remove('hidden')
    } else {
      this.customerPaidWrapperTarget.classList.add('hidden')
      this.customerPaidInputTarget.value = null
    }
  }
}
