import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'tabPanel']

  connect() {
    this.showTab()

    this.tabPanelTargets.forEach(element => {
      this.tabsObserver.observe(element)
    })
  }

  disconnect() {
    this.tabsObserver.disconnect()
  }

  change(e) {
    e.stopPropagation()
    e.preventDefault()
    this.index = this.tabTargets.indexOf(e.target)
  }

  showTab() {
    this.tabPanelTargets.forEach((el, i) => {
      if (i === this.index) {
        el.classList.remove('hidden')
      } else {
        el.classList.add('hidden')
      }
    })

    this.tabTargets.forEach((el, i) => {
      if (i === this.index) {
        el.classList.add(this.activeClass)
      } else {
        el.classList.remove(this.activeClass)
      }
    })
  }

  disableInputs(entries, _observer) {
    entries.forEach(panelEntry => {
      const inputController = this.application.getControllerForElementAndIdentifier(panelEntry.target, 'input-disable')

      // inputController will be undefined if there are no "input-disable" controllers defined as a child of the given panel
      if (!inputController) return

      if (panelEntry.isIntersecting) {
        inputController.enable()
      } else {
        inputController.disable()
      }
    })
  }

  get tabsObserver() {
    // this will need to be modified if we ever want to allow the option to submit data under a hidden tab panel
    this._tabsObserver ||= new IntersectionObserver(this.disableInputs.bind(this), { threshold: 0.01 })

    return this._tabsObserver
  }

  get index() {
    return parseInt(this.data.get('index'), 10)
  }

  get activeClass() {
    return this.data.get('activeClass') || 'tab--active'
  }

  set index(value) {
    this.data.set('index', value)
    this.showTab()
  }
}
