import makeFormsRemote from './makeFormsRemote'
import loadingErrorTemplate from './loadingErrorTemplate'

const ReactRailsUJS = require('react_ujs')

export default class RoleModelModal {
  static init() {
    const roleModelModal = new RoleModelModal()
    roleModelModal.enableModalOpen()
    roleModelModal.enableModalClose()
  }

  get modal() {
    return document.getElementById('modal')
  }

  get modalContent() {
    return document.getElementById('modal-content')
  }

  get modalBody() {
    return document.getElementById('modal-body')
  }

  get closeModalElement() {
    return document.getElementById('modal-close')
  }

  enableModalOpen() {
    document.querySelectorAll('a[data-modal]').forEach((element) => {
      element.addEventListener('ajax:send', (_event) => {
        if (element.dataset.large) { this.modalContent.classList.add('modal__content--large') }
        this.modal.classList.add('modal--active')
      })

      element.addEventListener('ajax:success', (e) => {
        this.modalBody.innerHTML = e.detail[0].body.innerHTML
        makeFormsRemote(this.modalBody, 'modal')
        ReactRailsUJS.handleMount()
      })

      element.addEventListener('ajax:error', (_event) => {
        this.displayError()
      })
    })
  }

  // Prevent trying to attach an event listener when the page doesn't have the modal.
  // For example login, etc
  enableModalClose() {
    if (this.closeModalElement) {
      this.closeModalElement.addEventListener('click', (e) => {
        e.preventDefault()
        this.modal.classList.remove('modal--active')

        setTimeout(() => {
          this.modalBody.innerHTML = ''
          this.modalContent.classList.remove('modal__content--large')
          ReactRailsUJS.handleUnmount()
        }, this.timeoutMilliseconds) // just wait until the animation finishes
      })
    }
  }

  get timeoutMilliseconds() {
    return parseInt(window.getComputedStyle(document.body).getPropertyValue('--modal-transition-speed'), 10) || 1000
  }

  displayError() {
    this.modalBody.innerHTML = loadingErrorTemplate
  }
}
