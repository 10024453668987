import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'paymentButtons',
    'paymentMethod',
    'paymentMethodWrapper',
    'cardDetails',
    'achModal',
    'bankNameInput',
    'accountNumberInput'
  ]

  static values = {
    populated: Boolean
  }

  connect() {
    if (this.populatedValue) {
      this.paymentButtonsTarget.classList.add('hidden')
    } else {
      this.paymentMethodWrapperTarget.classList.add('hidden')
    }

    window.ClearentOnSuccess = (_responseRaw, responseJSON) => {
      const details = responseJSON.payload.tokenResponse
      this.cardDetailsTarget.value = JSON.stringify(details)
      this.updatePaymentMethod(details)
    }
  }

  updatePaymentMethod(details) {
    if (details['card-type']) {
      this.paymentMethodTarget.value = `${details['card-type']} ending in ${details['last-four-digits']}`
    } else {
      this.paymentMethodTarget.value = `${details['bank-name']} ending in ${details['last-four-digits']}`
    }

    this.paymentButtonsTarget.classList.add('hidden')
    this.paymentMethodWrapperTarget.classList.remove('hidden')
  }

  openAchModal(e) {
    e.preventDefault()
    e.stopPropagation()
    this.achModalTarget.classList.add('modal--active')
    return false
  }

  saveAchModal(e) {
    e.preventDefault()
    e.stopPropagation()

    const bankName = this.bankNameInputTarget.value
    const accountNumber = this.accountNumberInputTarget.value

    this.achModalTarget.classList.remove('modal--active')

    this.updatePaymentMethod({
      'bank-name': bankName,
      'last-four-digits': accountNumber.slice(-4)
    })
    return false
  }

  closeAchModal(e) {
    e.preventDefault()
    e.stopPropagation()
    this.achModalTarget.classList.remove('modal--active')
    return false
  }
}
