import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['formNotes', 'modalNotes']

  saveFormNotes() {
    this.modalNotesTarget.value = this.formNotesTarget.value
  }

  saveModalNotes() {
    this.formNotesTarget.value = this.modalNotesTarget.value
  }
}
