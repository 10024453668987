import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  blur() {
    if (!this.element.value) return

    const value = parseFloat(this.element.value)
    this.element.value = value.toFixed(2)
    this.element.dispatchEvent(new Event('input', { bubbles: true }))
  }
}
