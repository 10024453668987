import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'lockedButton', 'unlockedButton']
  static values = { locked: Boolean }

  connect() {
    this.update()
  }

  toggle() {
    this.lockedValue = !this.lockedValue
    this.update()
  }

  update() {
    this.updateInputs()
    this.updateButtons()
  }

  updateInputs() {
    this.inputTargets.forEach(input => {
      if (this.lockedValue) {
        input.setAttribute('readonly', 'readonly')
      } else {
        input.removeAttribute('readonly')
      }
    })
  }

  updateButtons() {
    if (this.hasLockedButtonTarget) {
      this.lockedButtonTarget.classList.toggle('hidden', !this.lockedValue)
    }
    if (this.hasUnlockedButtonTarget) {
      this.unlockedButtonTarget.classList.toggle('hidden', this.lockedValue)
    }
  }
}
