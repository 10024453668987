import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'selectAddress',
    'manualAddressWrapper',
    'manualAddressInput',
    'gpsButton',

    'latitudeInput',
    'longitudeInput',
    'coordinatesWrapper'
  ]

  connect() {
    this.change()
  }

  change() {
    const addressId = this.selectAddressTarget.value

    if (addressId === '') {
      this.showGPSButton()
      this.showManualAddressSection()
    } else {
      this.hideGPSButton()
      this.hideAddressInputs()
      this.hideCoordinateInputs()
    }
  }

  showManualAddressSection() {
    if (this.hasLatitudeInputTarget && this.latitudeInputTarget.value) {
      this.showCoordinateInputs()
    } else {
      this.showAddressInputs()
    }
  }

  showAddressInputs() {
    this.manualAddressInputTargets.forEach(inputTarget => { inputTarget.disabled = false })
    this.manualAddressWrapperTarget.classList.remove('hidden')

    this.hideCoordinateInputs()
  }

  hideAddressInputs() {
    this.manualAddressInputTargets.forEach(inputTarget => { inputTarget.disabled = true })
    this.manualAddressWrapperTarget.classList.add('hidden')
  }

  // GPS

  showGPSButton() {
    if (!this.hasGpsButtonTarget) return

    this.gpsButtonTarget.classList.remove('hidden')
  }

  hideGPSButton() {
    if (!this.hasGpsButtonTarget) return

    this.gpsButtonTarget.classList.add('hidden')
  }

  showCoordinateInputs() {
    if (!this.hasCoordinatesWrapperTarget) return

    this.coordinatesWrapperTarget.classList.remove('hidden')
    this.hideAddressInputs()
  }

  hideCoordinateInputs() {
    if (!this.hasCoordinatesWrapperTarget) return

    this.coordinatesWrapperTarget.classList.add('hidden')
  }

  captureGpsLocation(event) {
    event.preventDefault()
    this.renderLoadingIndicator()

    navigator.geolocation.getCurrentPosition(
      (position) => this.gpsSuccess(position),
      (err) => this.gpsError(err),
      this.gpsOptions
    )
  }

  gpsSuccess(position) {
    const { latitude, longitude } = position.coords

    this.latitudeInputTargets.forEach(inputTarget => { inputTarget.value = latitude })
    this.longitudeInputTargets.forEach(inputTarget => { inputTarget.value = longitude })
    this.showCoordinateInputs()

    this.removeLoadingIndicator()
  }

  gpsError(error) {
    console.error(`ERROR(${error.code}): ${error.message}`)
    this.removeLoadingIndicator()
  }

  get options() {
    return {
      enableHighAccuracy: true,
      timeout: 5000
    }
  }

  // Loading Indicator

  renderLoadingIndicator() {
    document.body.appendChild(this.opaqueElement)
    document.body.appendChild(this.loader)
  }

  removeLoadingIndicator() {
    document.body.removeChild(this.opaqueElement)
    document.body.removeChild(this.loader)
  }

  get loader() {
    let loader = document.getElementsByClassName('loading-spinner')[0]

    if (!loader) {
      loader = document.createElement('div')
      loader.classList.add('loading-spinner')
    }

    return loader
  }

  get opaqueElement() {
    let element = document.getElementsByClassName('opaque')[0]

    if (!element) {
      element = document.createElement('div')
      element.classList.add('opaque')
    }

    return element
  }
}
