import currency from 'currency.js'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'basePriceInput',
    'cashPriceInput',
    'salesTaxRateInput',

    'lineItemDescriptionInput',
    'lineItemPriceInput',
    'lineItemQuantityInput',

    'depositInput',
    'dueAtDeliveryInput',

    'breakdownCashPrice',
    'breakdownLineItem',
    'breakdownSalesTax',
    'breakdownDeliveryCharge',
    'breakdownGrandTotal',

    'resalePriceHidden',
    'salesTaxHidden',
    'grandTotalHidden',

    'minimumDepositRatioHidden',
    'prepaidInput'
  ]

  connect() {
    if (this.hasDepositInputTarget && this.depositInputTarget.value) {
      const depositValue = parseFloat(this.depositInputTarget.value)
      const dueValue = parseFloat(this.dueAtDeliveryInputTarget.value)
      const minimumDepositRatio = this.minimumDepositRatioHiddenTarget.value
      this.depositRatio = Math.max((depositValue / (depositValue + dueValue)), minimumDepositRatio)
    } else {
      this.depositRatio = 1.0
    }
    this.change()
  }

  change(_event) {
    this.validateFields()
    this.updateCashCalculations()
    if (this.hasDepositInputTarget) {
      this.updateCalculations()
    } else {
      this.updateCalculations(false, false)
    }
  }

  validateFields() {
    const validate = (element) => {
      const value = parseFloat(element.value) || 0
      if (value < 0) {
        element.value = ''
      }
    }

    if (this.hasBasePriceInputTarget) {
      validate(this.basePriceInputTarget)
    }

    if (this.hasCashPriceInputTarget) {
      validate(this.cashPriceInputTarget)
    }
    validate(this.salesTaxRateInputTarget)
    if (this.hasDepositInputTarget) {
      validate(this.depositInputTarget)
    }
    if (this.hasDueAtDeliveryInputTarget) {
      validate(this.dueAtDeliveryInputTarget)
    }
  }

  updateCashCalculations() {
    if (this.hasBasePriceInputTarget) {
      this.cashPriceInputTarget.value = this.calculateCashPrice().toString()
    }
  }

  changeDeposit(_event) {
    const results = this.calculate()
    const invalidValue = (Number.isNaN(this.depositInputTarget.value) || this.depositInputTarget.value === '')
    const desiredDeposit = invalidValue ? 0 : parseFloat(this.depositInputTarget.value)
    if (results.grandTotal.value > 0) {
      this.depositRatio = Math.max((desiredDeposit / results.grandTotal.value), this.minimumDepositRatioHiddenTarget.value)
      this.updateCalculations(false, true)
    } else {
      this.updateCalculations(true, true)
    }
  }

  changeDueLater(_event) {
    const results = this.calculate()
    const invalidValue = (Number.isNaN(this.depositInputTarget.value) || this.dueAtDeliveryInputTarget.value === '')
    const desiredDue = invalidValue ? 0 : parseFloat(this.dueAtDeliveryInputTarget.value)
    if (results.grandTotal.value > 0) {
      this.depositRatio = Math.max(1 - (desiredDue / results.grandTotal.value), this.minimumDepositRatioHiddenTarget.value)
      this.updateCalculations(true, false)
    } else {
      this.updateCalculations(true, true)
    }
  }

  updateCalculations(updateDeposit = true, updateDue = true) {
    const results = this.calculate()

    let paymentAmounts
    if (this.hasPrepaidInputTarget) {
      paymentAmounts = this._calculatePaymentsForPrepaid(results)
    } else {
      paymentAmounts = this._calculatePaymentsForDepositRatio(results)
    }

    if (updateDeposit) {
      this.depositInputTarget.value = currency(paymentAmounts.deposit)
    }

    if (updateDue) {
      this.dueAtDeliveryInputTarget.value = currency(paymentAmounts.final)
    }

    this.updateBreakdown(results)
    this.updateHiddenFields(results)
  }

  _calculatePaymentsForPrepaid(results) {
    const prepaidAmount = parseFloat(this.prepaidInputTarget.value)

    const deposit = Math.min(prepaidAmount, results.grandTotal)
    const final = results.grandTotal - deposit

    return { deposit, final }
  }

  _calculatePaymentsForDepositRatio(results) {
    return {
      deposit: results.grandTotal * this.depositRatio,
      final: results.grandTotal * (1 - this.depositRatio)
    }
  }

  updateBreakdown(results) {
    this.breakdownCashPriceTarget.innerHTML = results.price.format()
    this.breakdownLineItemTargets.forEach(element => element.remove())
    const lineItemDescriptions = this.lineItemDescriptionInputTargets
    const lineItemQuantityInput = this.lineItemQuantityInputTargets

    if (this.hasLineItemPriceInputTarget) {
      this.breakdownCashPriceTarget.parentElement.parentElement.insertAdjacentHTML('beforebegin',
        `<tr data-sale-payment-target="breakdownLineItem">
              <td>Base Price</td> 
              <td><div class="tabular-input-align">${currency(this.basePriceInputTarget.value).format()}</div></td> `)
      this.lineItemPriceInputTargets.forEach((priceInput, index) => {
        const quantity = lineItemQuantityInput[index].value
        const description = lineItemDescriptions[index].value || 'Additional Item'
        const price = priceInput.value * quantity

        if (price > 0 || price < 0) {
          this.breakdownCashPriceTarget.parentElement.parentElement.insertAdjacentHTML(
            'beforebegin',
            `<tr data-sale-payment-target="breakdownLineItem">
            <td>${quantity}x ${description || '&nbsp;'}</td>
            <td>
              <div class="tabular-input-align">${currency(price).format()}</div>
            </td>
          </tr>`
          )
        }
      })
    }
    this.breakdownSalesTaxTarget.innerHTML = results.salesTax.format()
    this.breakdownGrandTotalTarget.innerHTML = results.grandTotal.format()
  }

  updateHiddenFields(results) {
    if (this.hasResalePriceHiddenTarget) {
      this.resalePriceHiddenTarget.value = results.price
    }
    this.salesTaxHiddenTarget.value = results.salesTax
    this.grandTotalHiddenTarget.value = results.grandTotal
  }

  calculateCashPrice() {
    const basePrice = parseFloat(this.basePriceInputTarget.value) || 0.0

    const lineItemsPrice = this.lineItemQuantityInputTargets.reduce((total, quantity, i) => {
      const lineItemTotal = parseInt(quantity.value, 10) * parseFloat(this.lineItemPriceInputTargets[i].value)
      return total + (lineItemTotal || 0.00)
    }, 0.0)

    return (basePrice + lineItemsPrice).toFixed(2)
  }

  calculate() {
    let price

    if (this.hasCashPriceInputTarget) {
      price = parseFloat(this.cashPriceInputTarget.value) || 0.0
    } else {
      price = 0.0
    }

    const salesTaxRate = parseFloat(this.salesTaxRateInputTarget.value) / 100.0 || 0.0
    const salesTax = salesTaxRate * price

    return {
      price: currency(price),
      salesTax: currency(salesTax),
      grandTotal: currency(price + salesTax)
    }
  }
}
