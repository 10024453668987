import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  remove(event) {
    event.preventDefault()
    const parent = this.element.parentElement
    this.element.remove()
    parent.dispatchEvent(new Event('sale:recalculate', { bubbles: true }))
    parent.dispatchEvent(new Event('contract:recalculate', { bubbles: true }))
  }
}
