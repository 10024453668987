import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    this.show = this.show.bind(this)
    this.removeElement = this.removeElement.bind(this)
  }

  connect() {
    setTimeout(this.show, 20)
  }

  show() {
    this.element.classList.add('is-showing')
  }

  dismiss(e) {
    e.preventDefault()
    this.element.classList.remove('is-showing')
    setTimeout(this.removeElement, 200)
  }

  removeElement() {
    this.element.remove()
  }
}
