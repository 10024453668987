import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select']
  static values = {
    url: String,
    emptyUrl: String
  }

  change() {
    let url
    if (this.selectTarget.value !== '') {
      url = `${this.urlValue}/${this.selectTarget.value}`
    } else {
      url = this.emptyUrlValue
    }
    if (this.data.get('turbo') === 'false') {
      window.location = url
    } else {
      window.Turbo.visit(url)
    }
  }
}
