import { Controller } from '@hotwired/stimulus'
import csrfToken from '../lib/csrfToken'

export default class extends Controller {
  static values = { url: String }

  async perform(e) {
    e.preventDefault()

    await fetch(this.urlValue, {
      method: 'Delete',
      headers: { 'X-CSRF-Token': csrfToken() }
    })
    this.element.remove()
  }
}
