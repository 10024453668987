import { Controller } from '@hotwired/stimulus'
import SignaturePad from 'signature_pad'

export default class extends Controller {
  static targets = ['pad', 'hidden', 'modal']
  static values = {
    template: String
  }

  openModal(e) {
    e.preventDefault()
    e.stopPropagation()
    this.modalTarget.classList.add('modal--active')
  }

  isPortrait() {
    const width = this.padTarget.width
    const height = this.padTarget.height

    return height > width
  }

  render() {
    const width = this.padTarget.width
    const height = this.padTarget.height

    const imageWidth = this.image.naturalWidth
    const imageHeight = this.image.naturalHeight

    const divisor = this.ratio * 2

    if (this.isPortrait()) {
      this.drawingContext.translate(width / divisor, height / divisor)
      this.drawingContext.rotate(Math.PI / 2)
      this.drawingContext.translate(-width / divisor, -height / divisor)

      const offsetX = (width - height) / divisor
      const offsetY = (height - width) / this.ratio

      this.drawingContext.translate(offsetX, offsetY)
      if (this.hiddenTarget.value) {
        this.signaturePad.fromDataURL(this.hiddenTarget.value)
      } else {
        this.drawingContext.drawImage(
          this.image,
          0,
          0,
          height / this.ratio,
          ((imageHeight / imageWidth) * height) / this.ratio
        )
      }

      this.drawingContext.translate(-offsetX, -offsetY)

      this.drawingContext.translate(width / divisor, height / divisor)
      this.drawingContext.rotate(-Math.PI / 2)
      this.drawingContext.translate(-width / divisor, -height / divisor)
    } else if (this.hiddenTarget.value) {
      this.signaturePad.fromDataURL(this.hiddenTarget.value)
    } else {
      this.drawingContext.drawImage(this.image, 0, 0, width / this.ratio, ((imageHeight / imageWidth) * width) / this.ratio)
    }
  }

  closeModal(e) {
    e.preventDefault()
    e.stopPropagation()
    this.modalTarget.classList.remove('modal--active')
  }

  clearDiagram(e) {
    e.preventDefault()
    e.stopPropagation()

    this.signaturePad.clear()
    this.hiddenTarget.value = ''
    this.render()
  }

  saveSignature() {
    this.hiddenTarget.value = this.padTarget.toDataURL('image/png')
  }

  connect() {
    this.ratio = Math.max(window.devicePixelRatio || 1, 1)
    const canvas = this.padTarget
    canvas.width = canvas.offsetWidth * this.ratio
    canvas.height = canvas.offsetHeight * this.ratio

    const context = canvas.getContext('2d')
    context.scale(this.ratio, this.ratio)
    this.signaturePad = new SignaturePad(canvas, {
      onEnd: this.saveSignature.bind(this)
    })

    this.image = new Image()
    this.image.src = this.templateValue

    this.drawingContext = context

    this.image.onload = () => {
      this.render()
    }

    this.resizeListener = () => {
      this.signaturePad.clear()
      this.disconnect()
      this.connect()
    }

    window.addEventListener('resize', this.resizeListener)
  }

  disconnect() {
    this.signaturePad.off()
    window.removeEventListener('resize', this.resizeListener)
  }
}
