import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.update()
  }

  blur() {
    this.update()
  }

  update() {
    if (this.element.value === '') return

    let salesTaxRate = parseFloat(this.element.value) || 0
    if (salesTaxRate < 0) {
      salesTaxRate = 0
    } else if (salesTaxRate > 10) {
      salesTaxRate = 10
    } else if (salesTaxRate > 1) {
      salesTaxRate /= 100.0
    }
    salesTaxRate *= 100.0

    if (Number.isInteger(salesTaxRate)) {
      salesTaxRate = salesTaxRate.toFixed(1)
    } else if (salesTaxRate === 7.000000000000001) { // let there be weeping and gnashing of teeth
      salesTaxRate = salesTaxRate.toFixed(1)
    }

    this.element.value = salesTaxRate
    this.element.dispatchEvent(new Event('input', { bubbles: true }))
  }
}
