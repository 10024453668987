import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['warning']

  update(event) {
    const value = parseFloat(event.target.value) || 0
    if (value === 0) {
      this.warningTarget.classList.remove('hidden')
    } else {
      this.warningTarget.classList.add('hidden')
    }
  }
}
