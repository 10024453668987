import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content']

  toggleDisplay() {
    if (this.hasContentTarget) {
      if (this.contentTarget.classList.contains('accordion__content--open')) {
        this.contentTarget.classList.remove('accordion__content--open')
      } else {
        this.contentTarget.classList.add('accordion__content--open')
      }
    }

    if (this.element.classList.contains('accordion--open')) {
      this.element.classList.remove('accordion--open')
    } else {
      this.element.classList.add('accordion--open')
    }
  }
}
