/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Polyfills per docs: https://github.com/rails/webpacker/blob/master/docs/es6.md#babel
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Rails from '@rails/ujs'
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import Chart from 'stimulus-chartjs'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

import Honeybadger from '@honeybadger-io/js'
import '@hotwired/turbo-rails'

import RoleModelModal from './helpers/rolemodel-modal'
import RoleModelConfirm from './helpers/rolemodel-confirm'

Rails.start()
ActiveStorage.start()

// require custom icons
require.context('images/icons', true)

const application = Application.start()
application.register('chart', Chart)
const context = require.context('../controllers', true, /\.js$/)
application.load(definitionsFromContext(context))
// Support component names relative to this directory:
const componentRequireContext = require.context('components', true)
const ReactRailsUJS = require('react_ujs')

ReactRailsUJS.useContext(componentRequireContext)

// Temp fix for turbo and react. Should be update in later version of react-rails
// See https://github.com/reactjs/react-rails/issues/1103 for details
ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount)
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount)

if (process.env.RAILS_ENV === 'production') {
  Honeybadger.configure({
    apiKey: process.env.HONEYBADGER_API_KEY,
    environment: process.env.HONEYBADGER_ENV, // ‘production’ or ‘review-app’ from app.json
    revision: process.env.HEROKU_SLUG_COMMIT, // provided by heroku
    breadcrumbsEnabled: true
  })
}

const onPageLoad = () => {
  // RoleModelPanel.init()
  RoleModelModal.init()
  RoleModelConfirm.init()
}

document.addEventListener('turbo:load', onPageLoad)
document.addEventListener('rolemodel:pageLoad', onPageLoad)

require('trix')
require('@rails/actiontext')
