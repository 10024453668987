import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    // based on a code example given at https://stackoverflow.com/a/60949881
    const incompleteImages = [...document.images].filter(i => !i.complete)
    if (incompleteImages.length > 0) {
      const resolveOnLoad = img => new Promise(resolve => {
        img.onload = resolve
        img.onerror = resolve
      })
      const promises = incompleteImages.map(resolveOnLoad)

      Promise.all(promises).then(() => {
        this.scroll()
      })
    } else {
      this.scroll()
    }
  }

  scroll() {
    const scrollable = this.element.parentNode
    scrollable.scrollTop = scrollable.scrollHeight
    this.element.remove()
  }
}
