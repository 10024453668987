import { Controller } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'
import csrfToken from '../lib/csrfToken'

/*
 * A form component to update the live contract form, primarily when the contract state changes.
 * Upon changing the governing state, this controller serializes key form elements to the server,
 *   which returns a turbo-stream updating those inputs to be consistent with the state-specific
 *   rules in effect.
 */
export default class extends Controller {
  static targets = [
    'contractStateInput',
    'physicalAddressStateInput',

    'basePriceInput',
    'cashPriceInput',
    'salesTaxRateInput',
    'lineItemQuantityInput',
    'lineItemDescriptionInput',
    'lineItemPriceInput',

    'rentalTerm',
    'deliveryCharge',
    'craSelected',
    'craInitialPayment',
    'dealerId',
    'manufacturerId',
    'promoCode',

    'structureHeight',
    'structureStain',
    'category',
    'diagram',
    'notes',
    'fenceAround',
    'accessConcerns'
  ]

  static values = {
    state: String
  }

  connect() {
    this._existingState = this.stateValue
  }

  change() {
    const newState = this.state()
    if (newState !== this.existingState()) {
      this.updateForm({ params: { field: 'state' } })
      this.contractStateInputTarget.value = newState
      this._existingState = newState
    }
  }

  updateForm(event) {
    const craSelected = this.hasCraSelectedTarget ? this.craSelectedTarget.checked : false
    const promoCode = this.hasPromoCodeTarget ? this.promoCodeTarget.value : ''
    const structureHeight = this.hasStructureHeightTarget ? this.structureHeightTarget.value : ''
    const structureStain = this.hasStructureStainTarget ? this.structureStainTarget.value : ''
    const fenceAround = this.hasFenceAroundTarget ? this.fenceAroundTarget.checked : false
    const accessConcerns = this.hasAccessConcernsTarget ? this.accessConcernsTarget.checked : false
    const diagram = this.hasDiagramTarget ? this.diagramTarget.value : ''
    const notes = this.hasNotesTarget ? this.notesTarget.value : ''
    const basePrice = this.hasBasePriceInputTarget ? this.basePriceInputTarget.value : '0.00'
    const cashPrice = this.hasCashPriceInputTarget ? this.cashPriceInputTarget.value : '0.00'

    // eslint-disable-next-line max-len
    const salesTaxRate = this.hasSalesTaxRateInputTarget && event.params.field === 'manufacturer' ? this.salesTaxRateInputTarget.value : undefined
    const addressLocked = this.physicalAddressStateInputTarget.getAttribute('readonly') === 'readonly'

    const lineItems = this.hasLineItemQuantityInputTarget ? this.lineItemQuantityInputTargets.map((quantity, index) => ({
      quantity: quantity.value,
      description: this.lineItemDescriptionInputTargets[index].value || '',
      price: this.lineItemPriceInputTargets[index].value || '0.00'
    })) : []
    fetch(
      '/contracts/update_form',
      {
        method: 'POST',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify({
          contract: {
            dealer_id: this.dealerIdTarget.value,
            manufacturer_id: this.manufacturerIdTarget.value,
            rental_term: this.rentalTermTarget.value,
            delivery_charge: this.deliveryChargeTarget.value,
            promo_code: promoCode,
            cra_selected: craSelected,
            fence_around: fenceAround,
            access_concerns: accessConcerns,
            serialized_structure_diagram: diagram,
            notes,
            base_price: basePrice,
            cash_price: cashPrice,
            sales_tax_rate: salesTaxRate,
            physical_address_attributes: {
              state_province: this.state()
            },
            properties: {
              structure: {
                height: structureHeight,
                deck_stain: structureStain,
                line_items: lineItems,
                category: this.categoryTarget.value
              },
              payment_scenarios: {
                scenario_cra_initial_payment: this.craInitialPaymentTarget.value
              }
            }
          },
          address_locked: addressLocked
        })
      }
    ).then(r => r.text()).then(html => Turbo.renderStreamMessage(html))
  }

  existingState() {
    return this._existingState
  }

  state() {
    return this.physicalAddressStateInputTarget.value || this.stateValue
  }
}
