import { Controller } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'
import csrfToken from '../lib/csrfToken'

/*
 * A form component to update the live manufacturer sale form, primarily when the manufacturer changes.
 * This controller serializes key form elements to the server,
 *   which returns a turbo-stream updating those inputs to be consistent with the manufacturer-specific
 *   rules in effect.
 */
export default class extends Controller {
  static targets = [
    'manufacturerId',
    'dealerId',
    'basePriceInput',
    'cashPriceInput',
    'salesTaxRateInput',
    'initialPaymentInput',
    'finalPaymentInput',
    'initialPaymentStrategy',
    'parentSaleId'
  ]

  change() {
    this.updateForm()
  }

  updateForm() {
    const basePrice = this.hasBasePriceInputTarget ? this.basePriceInputTarget.value : '0.00'
    const cashPrice = this.hasCashPriceInputTarget ? this.cashPriceInputTarget.value : '0.00'
    const initialPayment = this.hasInitialPaymentInputTarget ? this.initialPaymentInputTarget.value : '0.00'
    const finalPayment = this.hasFinalPaymentInputTarget ? this.finalPaymentInputTarget.value : '0.00'

    // eslint-disable-next-line max-len
    const salesTaxRate = this.hasSalesTaxRateInputTarget ? this.salesTaxRateInputTarget.value : undefined

    const parentSaleId = this.hasParentSaleIdTarget ? this.parentSaleIdTarget.value : undefined

    const initialPaymentStrategy = this.initialPaymentStrategyTargets.find(element => element.checked)?.value

    fetch(
      '/manufacturer_sales/update_form',
      {
        method: 'POST',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify({
          manufacturer_sale: {
            parent_sale_id: parentSaleId,
            dealer_id: this.dealerIdTarget.value,
            manufacturer_id: this.manufacturerIdTarget.value,
            base_price: basePrice,
            cash_price: cashPrice,
            initial_payment_strategy: initialPaymentStrategy,
            initial_payment: initialPayment,
            final_payment: finalPayment,
            sales_tax_rate: salesTaxRate
          }
        })
      }
    ).then(r => r.text()).then(html => Turbo.renderStreamMessage(html))
  }
}
