import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select', 'turboFramePanel', 'newStructureHiddenInput']
  static values = {
    context: String,
    dealerId: String,
    offline: { type: Boolean, default: false }
  }

  connect() {
    this._formReloadNeeded = false
    if (this.selectTarget.value) {
      this.updateInventoryPanel()
    }
  }

  asNew() {
    this.selectTarget.disabled = true
    this.newStructureHiddenInputTargets.forEach(target => { target.disabled = false })
    if (this.turboFramePanelTarget.src || this._formReloadNeeded) {
      const url = `/structures/new/panel?context=${this.contextValue}&dealer_id=${this.dealerIdValue}&offline=${this.offlineValue}`
      this.turboFramePanelTarget.src = url
    }
  }

  fromInventory() {
    this.selectTarget.disabled = false
    this.newStructureHiddenInputTargets.forEach(target => { target.disabled = true })
    this.updateInventoryPanel()
  }

  updateInventoryPanel() {
    const structureId = this.selectTarget.value
    this._formReloadNeeded = true
    if (structureId) {
      this.turboFramePanelTarget.src = `/structures/${structureId}/panel?context=${this.contextValue}&dealer_id=${this.dealerIdValue}`
    } else {
      this.turboFramePanelTarget.innerHTML = ''
    }
  }
}
