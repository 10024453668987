import { Controller } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'
import csrfToken from '../lib/csrfToken'

export default class extends Controller {
  static targets = ['input']
  static values = {
    url: String,
    method: String,
    resource: String
  }

  async submit() {
    const formData = new FormData()

    formData.append('resource', this.resourceValue)

    this.inputTargets.forEach(input => {
      formData.append(input.name, input.value)
    })

    const response = await fetch(this.urlValue, {
      method: this.formMethod,
      body: formData,
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': csrfToken()
      }
    })

    const responseText = await response.text()
    Turbo.renderStreamMessage(responseText)
  }

  get formMethod() {
    return this.hasMethodValue ? this.methodValue : 'POST'
  }
}
