import Chart from 'stimulus-chartjs'

import { Chart as ChartJS } from 'chart.js'
import datalabelsPlugin from 'chartjs-plugin-datalabels'

export default class extends Chart {
  static values = {
    data: Object,
    options: Object,
    type: { type: String, default: 'line' }
  }

  connect() {
    ChartJS.register(datalabelsPlugin)
    this._measurementContext = document.createElement('canvas').getContext('2d')

    super.connect()

    // regenerate chart since some of our settings depend on having been rendered once
    this.chart.options = this.defaultOptions
    this.chart.update('none')
  }

  get defaultOptions() {
    const style = getComputedStyle(this.element)

    const colorPrimaryBase = style.getPropertyValue('--color-primary-base')
    const colorNeutralBase = style.getPropertyValue('--color-neutral-base')
    const lineHeight = style.getPropertyValue('--chart-scale')

    const barCount = this.dataValue.datasets[0].data.length
    const defaultFontString = `${lineHeight}px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif`

    const measureText = (text, fontString = defaultFontString) => {
      this._measurementContext.font = fontString
      return this._measurementContext.measureText(text)
    }

    const getOption = (optionContext) => optionContext.dataset.data[optionContext.dataIndex]
    const getBar = (optionContext) => optionContext.chart._sortedMetasets[0].data[optionContext.dataIndex]

    const labelFits = (optionContext, label, fontString = defaultFontString) => {
      const barLength = getBar(optionContext).width
      const textWidth = measureText(label, fontString).width + 10 // text width + padding

      return barLength > textWidth
    }

    const getVerticalOffset = (optionContext) => {
      const bar = getBar(optionContext)
      return (bar.height / 2)
    }

    const getTopAngle = (optionContext) => {
      const option = getOption(optionContext)
      const optionLength = measureText(option.formattedValue, `${defaultFontString} bold`).width / 2

      const vertical = getVerticalOffset(optionContext) * 2

      const direction = labelFits(optionContext, option.formattedValue, `${defaultFontString} bold`) ? -1 : 1

      const angle = Math.atan2(vertical, (direction * optionLength / 2) + direction * 1) * (180 / Math.PI)
      return -angle
    }

    const getTopDistance = (optionContext) => {
      const angle = ((getTopAngle(optionContext) + 90) / 180) * Math.PI

      const hypotenuse = getVerticalOffset(optionContext) / Math.cos(angle)

      return hypotenuse
    }

    const formatContractsLabel = (option, _optionContext) => `${option.quantity} Contracts`

    return {
      events: [],
      indexAxis: 'y',
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 2,
      barThickness: lineHeight * 2,
      backgroundColor: (optionContext) => {
        if (optionContext.dataIndex === (barCount - 1)) return colorPrimaryBase

        return colorNeutralBase
      },
      plugins: {
        legend: {
          display: false
        },
        datalabels: {
          labels: {
            value: {
              align: getTopAngle,
              anchor: 'end',
              offset: getTopDistance,
              font: {
                weight: 'bold',
                size: lineHeight
              },
              padding: {
                bottom: 2,
                top: 2
              },
              formatter: (value, _ctx) => `${value.formattedValue}`
            },
            quantity: {
              align: (optionContext) => (labelFits(optionContext, formatContractsLabel(getOption(optionContext))) ? 'start' : 'end'),
              color: (optionContext) => (labelFits(optionContext, formatContractsLabel(getOption(optionContext))) ? 'white' : 'black'),
              anchor: 'end',
              font: {
                size: lineHeight
              },
              formatter: formatContractsLabel
            }
          }
        }
      },
      parsing: {
        xAxisKey: 'value',
        yAxisKey: 'year'
      },
      scales: {
        x: {
          display: false,
          ticks: {
            beginAtZero: true
          }
        },
        y: {
          ticks: {
            font: {
              size: lineHeight
            }
          }
        }
      }
    }
  }
}
