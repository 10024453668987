import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  keydown(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      return false
    }
  }
}
