import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['select']
  static values = {
    url: String
  }

  change() {
    const url = `${this.urlValue}${this.selectTarget.value}`
    if (this.data.get('turbo') === 'false') {
      window.location = url
    } else {
      window.Turbo.visit(url)
    }
  }
}
