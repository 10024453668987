import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    this.findFileInput = this.findFileInput.bind(this)
    this.removePreviews = this.removePreviews.bind(this)
  }

  perform(e) {
    e.preventDefault()

    const input = this.findFileInput(this.element.nextElementSibling)

    this.removePreviews(0, input.files.length, input.previousElementSibling)
    input.remove()
    this.element.remove()
  }

  /* eslint-disable no-else-return */
  findFileInput(el) {
    if (el.tagName === 'INPUT') {
      return el
    } else {
      return this.findFileInput(el.nextElementSibling)
    }
  }

  removePreviews(current, count, el) {
    if (current < count) {
      this.removePreviews(current + 1, count, el?.previousElementSibling)
      if (el !== this.element) { el.remove() }
    }
  }
}
