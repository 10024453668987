import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['field']

  generate(event) {
    event.preventDefault()
    this.fieldTarget.value = this.data.get('contractNumber')
  }
}
